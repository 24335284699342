import {
  configure,
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import Vue from 'vue'

/* eslint-disable camelcase */
import {
  email,
  max,
  min,
  numeric,
  regex,
  required,
} from 'vee-validate/dist/rules'
/* eslint-enable camelcase */
import deepMerge from 'lodash/merge'
import moment from 'moment'
import en from 'vee-validate/dist/locale/en.json'
import CHECK_PHONE_MUTATION from '~/apollo/mutations/checkPhoneNumber'

/* eslint-disable */
/**
 * This is a part of VeeValidate code that is not exposed.
 *
 * It was copied as a part of a workaround we need to translate
 * validation errors from API to human readable messages.
 *
 * It is used in Vue.protorype.getFieldValidationMessage
 *
 * TODO We need to remove it after VeeValidate exposed access to its dictionary.
 */
var __assign = function () {
  __assign =
    Object.assign ||
    function __assign(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i]
        for (var p in s)
          if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
      }
      return t
    }
  return __assign.apply(this, arguments)
}
var isObject = function (obj) {
  return obj !== null && obj && typeof obj === 'object' && !Array.isArray(obj)
}
var isCallable = function (func) {
  return typeof func === 'function'
}
var interpolate = function (template, values) {
  return template.replace(/\{([^}]+)\}/g, function (_, p) {
    return p in values ? values[p] : '{' + p + '}'
  })
}
function merge(target, source) {
  Object.keys(source).forEach(function (key) {
    if (isObject(source[key])) {
      if (!target[key]) {
        target[key] = {}
      }
      merge(target[key], source[key])
      return
    }
    target[key] = source[key]
  })
  return target
}
var Dictionary = /** @class */ (function () {
  function Dictionary(locale, dictionary) {
    this.container = {}
    this.locale = locale
    this.merge(dictionary)
  }
  Dictionary.prototype.resolve = function (field, rule, values) {
    return this.format(this.locale, field, rule, values)
  }
  Dictionary.prototype._hasLocale = function (locale) {
    return !!this.container[locale]
  }
  Dictionary.prototype.format = function (locale, field, rule, values) {
    var message
    // find if specific message for that field was specified.
    var dict =
      this.container[locale] &&
      this.container[locale].fields &&
      this.container[locale].fields[field]
    if (dict && dict[rule]) {
      message = dict[rule]
    }
    if (!message && this._hasLocale(locale) && this._hasMessage(locale, rule)) {
      message = this.container[locale].messages[rule]
    }
    if (!message) {
      if (rule === 'This email address is already in use') {
        message = rule
      } else {
        message = '{_field_} is not valid.'
      }
    }
    if (this._hasName(locale, field)) {
      field = this.getName(locale, field)
    }
    return isCallable(message)
      ? message(field, values)
      : interpolate(message, __assign(__assign({}, values), { _field_: field }))
  }
  Dictionary.prototype.merge = function (dictionary) {
    merge(this.container, dictionary)
  }
  Dictionary.prototype.hasRule = function (name) {
    var locale = this.container[this.locale]
    if (!locale) return false
    return !!(locale.messages && locale.messages[name])
  }
  Dictionary.prototype.getName = function (locale, key) {
    return this.container[locale].names[key]
  }
  Dictionary.prototype._hasMessage = function (locale, key) {
    return !!(
      this._hasLocale(locale) &&
      this.container[locale].messages &&
      this.container[locale].messages[key]
    )
  }
  Dictionary.prototype._hasName = function (locale, key) {
    return !!(
      this._hasLocale(locale) &&
      this.container[locale].names &&
      this.container[locale].names[key]
    )
  }
  return Dictionary
})()
/* eslint-enable */

export default ({ app }) => {
  // Install rules
  extend('regex', regex)
  extend('numeric', numeric)
  extend('between', {
    params: ['minValue', 'maxValue'],
    message: `The {_field_} must be between {minValue} and {maxValue}`,
    validate(value, { minValue, maxValue }) {
      return (
        parseInt(value) >= parseInt(minValue) &&
        parseInt(value) <= parseInt(maxValue)
      )
    },
  })
  extend('required', {
    ...required,
    message: `The {_field_} is required`,
  })
  extend('email', {
    ...email,
    message: `The email address is not valid.`,
  })
  extend('min', {
    ...min,
    params: ['length'],
    message: 'The {_field_} field must have at least {length} characters',
  })
  extend('max', {
    ...max,
    params: ['length'],
    message: 'The {_field_} field must have up to {length} characters',
  })
  extend('min_value', {
    params: ['minValue'],
    message: `The {_field_} must be {minValue} or more.`,
    validate(val, { minValue }) {
      return parseInt(val) >= parseInt(minValue)
    },
  })
  extend('valid_phone', {
    message: `We weren't able to verify this phone number`,
    validate: async (value) => {
      let res = null
      try {
        res = await app.apolloProvider.defaultClient.mutate({
          mutation: CHECK_PHONE_MUTATION,
          variables: {
            input: {
              phoneNumber: value.replace(/[^\d]/g, ''),
            },
          },
        })
      } catch (_err) {
        return false
      }
      return res.data.checkPhoneNumber.isValid || false
    },
  })
  extend('phone', {
    message: 'Not a valid phone number.',
    validate: (value) => /^\D*(?:\d\D*){10}$/.test(value),
  })
  extend('fullName', {
    message: 'Please enter a first and last name',
    validate(value) {
      return /\S+\s\S+/.test(value)
    },
  })
  extend('date', {
    message: 'Please provide valid date',
    validate(value) {
      return moment(value, 'MM/DD/YYYY', true).isValid()
    },
  })
  extend('usdate', {
    message: 'Please provide valid date in MM/DD/YYYY format',
    validate(value) {
      return moment(value, 'MM/DD/YYYY', true).isValid()
    },
  })
  extend('hour', {
    message: 'Please provide valid hour in hh:mm format',
    validate(value) {
      return moment(`2014-12-13 ${value}`, 'MM/DD/YYYY HH:mm', true).isValid()
    },
  })
  extend('birthdate', {
    message: 'Date must be before today and after 1900',
    validate(value) {
      const checkYear = moment(value, 'MM/DD/YYYY', true).year()
      return (
        moment(value, 'MM/DD/YYYY', true).isBefore(moment(new Date())) &&
        checkYear >= 1900
      )
    },
  })
  extend('hasEntry', {
    message: 'Please select at least one entry',
    validate: (value) => value && value.length > 0,
  })
  extend(
    'crossFieldMinValue',
    {
      params: ['target', 'comparedTo'],
      validate: (value, { target }) => {
        if (value && target) {
          return parseFloat(value) >= parseFloat(target)
        }
        return true
      },
      message: '{_field_} cannot be lower than {comparedTo}',
    },
    {
      hasTarget: true,
    },
  )
  extend(
    'crossFieldMaxValue',
    {
      params: ['target', 'comparedTo'],
      validate: (value, { target }) => {
        if (value && target) {
          return parseFloat(value) <= parseFloat(target)
        }
        return true
      },
      message: '{_field_} cannot be greater than {comparedTo}',
    },
    {
      hasTarget: true,
    },
    extend('url', {
      validate(value) {
        const urlRegex =
          /((http|https|ftp|ftps):\/\/)?[a-zA-Z0-9\-.]+.[a-zA-Z]{2,}(\/\S*)?/
        return urlRegex.test(value)
      },
      message: 'The {_field_} is not a valid URL',
    }),
    extend('decimal', {
      validate: (value, { decimals = '*', separator = '.' } = {}) => {
        if (value === null || value === undefined || value === '') {
          return {
            valid: false,
          }
        }
        if (Number(decimals) === 0) {
          return {
            valid: /^-?\d*$/.test(value),
          }
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
        const regex = new RegExp(
          `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`,
        )
        return regex.test(value)
      },
      message: 'Only decimal values are available',
    }),
    extend('npi', {
      validate: (value) => {
        // Luhn algorithm for validating NPI number
        let tmp
        let sum
        let i
        let j
        i = value.length
        if (i === 15 && value.indexOf('80840', 0, 5) === 0) sum = 0
        else if (i === 10) sum = 24
        else return false
        j = 0
        while (i !== 0) {
          tmp = value.charCodeAt(i - 1) - '0'.charCodeAt(0)
          if (j++ % 2 !== 0) {
            if ((tmp <<= 1) > 9) {
              tmp -= 10
              tmp++
            }
          }
          sum += tmp
          i--
        }
        if (sum % 10 === 0) return true
        else return false
      },
      message: 'The NPI is not valid.',
    }),
    extend('calendly', {
      validate: (value) => {
        if (!value) return false
        return /calendly\.com\/*/.test(value)
      },
      message: 'The Calendly Scheduling link is not valid.',
    }),
    extend('wendiSyntax', {
      validate: (value) => {
        if (value.toString().includes('{') || value.toString().includes('}')) {
          // Validate the number of curly brackets
          const map = [...value].reduce((a, e) => {
            a[e] = a[e] ? a[e] + 1 : 1
            return a
          }, {})
          if (map['{'] !== map['}'] || (map['{'] + map['}']) % 4 !== 0) {
            return false
          }

          // Get all tags in the text
          const tags = value.match(/{{(.*?)}}/gi)
          if (tags) {
            // Validate edge scenarios like }}TAG{{
            if (tags.length !== (map['{'] + map['}']) / 4) {
              return false
            }
            for (const tag of tags) {
              if (!/{{(.*?)}}/.test(tag)) {
                // Validate curly brackets for current tag
                return false
              } else if (!/{{\S+}}/.test(tag)) {
                // Validate no spaces inside the tag
                return false
              } else if (!/{{[A-Z]+(?:_[A-Z]+)*}}/.test(tag)) {
                // Validate tag only contains uppercase letters and underscores
                return false
              }
            }
          }
          return true
        }
        return true
      },
      message:
        'The tag syntax is not valid. Please, use {{ for open and }} for close. E.g. {{PROVIDER_NAME}}',
    }),
  )

  // Install messages
  const messages = deepMerge(en, {
    names: {
      fullName: 'name',
      firstName: 'first name',
      lastName: 'last name',
      phoneNumber: 'phone number',
      recipientPhoneNumber: 'Phone number',
    },
    messages: {
      not_unique: 'This {_field_} already exists.',
      date_format: 'This {_field_} is invalid',
      not_included_in_list: 'Please select value from the list',
      no_match: 'The {_field_} does not match.',
      must_be_phone: 'Not a valid phone number.',
    },
    fields: {
      price: {
        max_value:
          "Your price may not exceed the client's budget by 20%. This price cannot exceed { maxValue }.",
      },
      validationCode: {
        invalid_length: 'Incorrect code',
      },
      no_match: (field) => {
        return 'The {{field}} does not match.'
      },
      must_be_phone: (field) => {
        return 'Not a valid phone number.'
      },
    },
  })

  const messageDictionary = new Dictionary('en', {
    en: messages,
  })
  Vue.prototype.getFieldValidationMessage = function (
    field,
    rule,
    values = {},
  ) {
    return messageDictionary.format('en', field, rule, values)
  }

  localize('en', { en: messages })

  configure({
    mode: 'lazy', // Triggered when the user leaves the input (on blur or change)
  })
}

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
